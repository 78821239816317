/* log-responsive.css */

/* Media query for small screens (mobile) */
@media screen and (max-width: 360px) {
  .table-container {
    padding: 10px; /* Adjust spacing for small screens */
  }
}
@media screen and (min-width:350) and (max-height:730px) {
  .table-container {
    padding: 10px; /* Adjust spacing for small screens */
  }
  @media screen and (max-width: 375px) {
    .table-container {
      padding: 10px; /* Adjust spacing for small screens */
    }
  }
  @media screen and (max-width: 412px)and (max-height:915px) {
    .table-container {
      padding: 10px; /* Adjust spacing for small screens */
    }
  }
  
    /* Adjust font size and padding for better mobile readability */
    .table-container input,
    .pagination-container {
      font-size: 14px;
      padding: 5px;
    }
  
    .pagination-container {
      text-align: center;
    }
  
    .pagination-container .pagination {
      justify-content: center;
    }
  }
  
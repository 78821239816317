/* dashboardStyles.css */
.card-widget {
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.card-widget .card-img {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  max-height: 150px;
  object-fit: cover;
}

.card-widget .card-body {
  padding: 1.25rem;
}

.card-widget .card-title {
  font-size: 1rem;
  margin-bottom: 0.5rem;
}

.card-widget .card-content {
  font-size: 0.875rem;
  color: #6b7280;
}
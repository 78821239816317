/* pagination.css */

.pagination {
    display: inline-flex;
    border-radius: 0.25rem;
    margin: 0;
  }
  
  .pagination .page-item {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0;
    cursor: pointer;
    transition: background-color 0.2s, color 0.2s;
  }
  
  .pagination .page-item.active {
    background-color: #007bff;
    color: #fff;
  }
  
  .pagination .page-item:hover {
    background-color: #f8f9fa;
    color: #007bff;
  }
  